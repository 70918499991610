<template>
  <div class="companynews">
    <div class="line"></div>
    <h2>政策解读</h2>
    <div class="news-wrapper">
      <div
        class="news-item"
        v-for="(item, index) in 8"
        :key="index"
        @click="JumpNews(index)"
      >
        <div class="news-img">
          <img src="./../../assets/img/11.jpg" alt="" />
        </div>
        <div class="news-content">
          <div class="news-date">
            <span>30</span>
            <span></span>
            <span>12</span>
            <span>2012</span>
          </div>
          <div class="news-line"></div>
          <div class="news-right">
            <h2>山水物联网络货运平台成功上线...</h2>
            <p>
              山水物联网络货运平台于2021年07月20日成功上线，开启辽宁省本溪市溪湖区彩屯北路附近
              —
              辽宁省盘锦市大洼区兴一路盘锦山水首条运输线路运营。山水物联网络货运平台于2021年07月20日成功上线，开启辽宁省本溪市溪湖区彩屯北路附近
              —
              辽宁省盘锦市大洼区兴一路盘锦山水首条运输线路运营。山水物联网络货运平台于2021年07月20日成功上线....
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="next-button">
      <span class="icon-wrap-left">
        <i class="el-icon-arrow-left"></i>
      </span>
      <span class="icon-wrap-right">
        <i class="el-icon-arrow-right"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    JumpNews(id) {
      this.$router.push({
        path: "/baseHome/newscontent",
        query: { newsId: id },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
.companynews {
  .news-wrapper {
    .news-item {
      &:hover {
        transition: all 0.5s;
        background-color: #0062db;
        .news-date,
        .news-right p,
        .news-right h2 {
          color: #fff !important;
        }
      }
      display: flex;
      width: 100%;
      height: 230px;
      border-radius: 8px;
      margin-bottom: 38px;
      padding: 14px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;
      .news-content {
        display: flex;
        align-items: center;
        .news-date {
          position: relative;
          color: #888888;
          span:nth-child(1) {
            font-size: 30px;
          }
          span:nth-child(2) {
            display: inline-block;
            width: 1px;
            height: 18px;
            background-color: #888888;
            transform: rotate(30deg);
            margin: 0 6px;
          }
          span:nth-child(3) {
            font-size: 20px;
          }
          span:nth-child(4) {
            position: absolute;
            bottom: -18px;
            left: 20px;
            font-size: 14px;
          }
        }
        .news-line {
          height: 110px;
          width: 1px;
          background-color: #e5e5e5;
          margin: 0 40px;
        }
        .news-right {
          width: 870px;
          h2 {
            margin-bottom: 30px;
          }
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 30px;
            font-size: 14px;
            color: #888;
          }
        }
      }
    }
    .news-img {
      margin-right: 48px;
    }
  }

  .next-button {
    width: 100%;
    margin-bottom: 158px;
    text-align: center;
    .icon-wrap-left {
      display: inline-block;
      margin-right: 30px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #0a68dc;
      box-sizing: border-box;
      text-align: center;
      line-height: 56px;
      .el-icon-arrow-left {
        cursor: pointer;
        font-size: 30px;
        color: #0a68dc;
        font-weight: bolder;
      }
    }
    .icon-wrap-right {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #0a68dc;
      box-sizing: border-box;
      text-align: center;
      line-height: 56px;
      .el-icon-arrow-right {
        cursor: pointer;
        font-size: 30px;
        color: #0a68dc;
        font-weight: bolder;
      }
    }
  }
}
</style>